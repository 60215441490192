var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import dependencyReady from '@/client/js/utils/dependencyReady';
import createTrackingData from './createTrackingData';
import { progressState as benchmarks } from './progressState';
import { logError, getMedia } from './helpers';
/**
 * Initializes Permutive event tracking for a JW Player instance.
 *
 * @param {Player} player - The JW Player instance.
 * @param {PostMeta} postMeta - Metadata about the post or article.
 *
 * @see {@link https://docs.jwplayer.com/players/reference/javascript-player-api-introduction} for JW Player API documentation.
 * @see {@link https://developer.permutive.com/} for Permutive API documentation.
 *
 * @returns {void}
 */
var jwPermutive = function (player, postMeta) { return __awaiter(void 0, void 0, void 0, function () {
    var getValidPlayer, permutiveEvents;
    return __generator(this, function (_a) {
        getValidPlayer = function () { return __awaiter(void 0, void 0, void 0, function () {
            var MAX_RETRY_ATTEMPTS, attempts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        MAX_RETRY_ATTEMPTS = 20;
                        attempts = 0;
                        _a.label = 1;
                    case 1:
                        if (!(!player.getPlaylistItem()
                            && attempts < MAX_RETRY_ATTEMPTS)) return [3 /*break*/, 3];
                        attempts += 1;
                        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 250); })];
                    case 2:
                        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
                        _a.sent();
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/, player];
                }
            });
        }); };
        permutiveEvents = function () { return __awaiter(void 0, void 0, void 0, function () {
            var permutive, getVideoMeta, videoMeta, trackingData, isThrottled, TIMEOUT, hasSeeked, progressHandler, globalAdItem, globalAdTime, globalTimeObj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        permutive = window.permutive;
                        getVideoMeta = function () { return __awaiter(void 0, void 0, void 0, function () {
                            var validPlayer, getMediaData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getValidPlayer()];
                                    case 1:
                                        validPlayer = _a.sent();
                                        return [4 /*yield*/, getMedia(validPlayer)];
                                    case 2:
                                        getMediaData = _a.sent();
                                        return [2 /*return*/, getMediaData];
                                }
                            });
                        }); };
                        return [4 /*yield*/, getVideoMeta()];
                    case 1:
                        videoMeta = _a.sent();
                        trackingData = createTrackingData({
                            player: player,
                            videoMeta: videoMeta,
                            postMeta: postMeta,
                            eventName: 'VideoLoad',
                        });
                        try {
                            // Send the event.
                            permutive === null || permutive === void 0 ? void 0 : permutive.track('VideoLoad', trackingData);
                        }
                        catch (e) {
                            logError(e);
                        }
                        // Triggers each time the player enters the playing state (sans ads).
                        player.on('play', function () { return __awaiter(void 0, void 0, void 0, function () {
                            var VideoPlayTrackingData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getVideoMeta()];
                                    case 1:
                                        // Update videoMeta in case it has changed.
                                        videoMeta = _a.sent();
                                        VideoPlayTrackingData = createTrackingData({
                                            player: player,
                                            videoMeta: videoMeta,
                                            postMeta: postMeta,
                                            eventName: 'VideoPlay',
                                        });
                                        try {
                                            permutive === null || permutive === void 0 ? void 0 : permutive.track('VideoPlay', VideoPlayTrackingData);
                                        }
                                        catch (e) {
                                            logError(e);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        isThrottled = false;
                        TIMEOUT = 1000;
                        hasSeeked = false;
                        progressHandler = function (eventName, timeObj, adItem) {
                            // If adTime was passed in, we can use "progress".
                            // Otherwise, we need to calculate it from the timeObj.
                            var position = timeObj.position, duration = timeObj.duration, seeked = timeObj.seeked;
                            // store the seeked state globally.
                            if (seeked) {
                                hasSeeked = true;
                            }
                            if (isThrottled
                                || typeof duration !== 'number'
                                || typeof position !== 'number') {
                                return;
                            }
                            // Loop through the benchmarks.
                            benchmarks.state.forEach(function (benchmark, index) {
                                var _a;
                                if (
                                // If the progress percentage is greater than the current benchmark,
                                position / duration > benchmark.value
                                    // and the current benchmark has not been sent.
                                    && benchmark.sent === false) {
                                    var progressTrackingData = createTrackingData({
                                        player: __assign(__assign({}, player), { seeked: hasSeeked }),
                                        videoMeta: videoMeta,
                                        postMeta: postMeta,
                                        eventName: eventName,
                                        adItem: (_a = __assign(__assign({}, adItem), { position: position })) !== null && _a !== void 0 ? _a : null,
                                    });
                                    try {
                                        permutive === null || permutive === void 0 ? void 0 : permutive.track(eventName, progressTrackingData);
                                        // Mark the event as sent.
                                        benchmarks.updateState(index, true);
                                    }
                                    catch (e) {
                                        logError(e);
                                    }
                                    finally {
                                        // Throttle the event for a second.
                                        isThrottled = true;
                                        // Also wait one second to u[date] hasSeeked in case
                                        // user seeked beyond more than one progress benchmark.
                                        setTimeout(function () {
                                            isThrottled = false;
                                            hasSeeked = false;
                                        }, TIMEOUT);
                                    }
                                }
                            });
                        };
                        // Triggers up to 10/sec during video playback.
                        player.on('time', function (timeObj) {
                            globalTimeObj = timeObj;
                            progressHandler('VideoProgress', timeObj);
                        });
                        // Triggers each time user is seeking video.
                        player.on('seek', function () {
                            progressHandler('VideoProgress', __assign(__assign({}, globalTimeObj), { seeked: true }));
                        });
                        // If the user finishes the video or clicks next,
                        // reset the progress benchmarks and seeked state.
                        player.on('complete', function () {
                            benchmarks.resetState();
                        });
                        player.on('nextClick', function () {
                            benchmarks.resetState();
                        });
                        // Triggers when ad is parsed, loaded, and is ready for display.
                        player.on('adItem', function (adItem) {
                            globalAdItem = adItem;
                            var videoAdPlayTrackingData = createTrackingData({
                                player: player,
                                videoMeta: videoMeta,
                                postMeta: postMeta,
                                eventName: 'VideoAdPlay',
                                adItem: adItem,
                            });
                            try {
                                permutive === null || permutive === void 0 ? void 0 : permutive.track('VideoAdPlay', videoAdPlayTrackingData);
                            }
                            catch (e) {
                                logError(e);
                            }
                        });
                        // Triggers up to 10/sec during ad playback.
                        player.on('adTime', function (adTime) {
                            globalAdTime = adTime;
                            progressHandler('VideoAdProgress', adTime, globalAdItem);
                        });
                        // Triggers when ad is clicked and user is redirected to the ad landing page.
                        player.on('adClick', function () {
                            var adClickTrackingData = createTrackingData({
                                player: player,
                                videoMeta: videoMeta,
                                postMeta: postMeta,
                                eventName: 'VideoAdClick',
                                adItem: __assign(__assign({}, globalAdItem), { position: globalAdTime.position }),
                            });
                            try {
                                permutive === null || permutive === void 0 ? void 0 : permutive.track('VideoAdClick', adClickTrackingData);
                            }
                            catch (e) {
                                logError(e);
                            }
                        });
                        // Triggers when the ad has completed playback.
                        player.on('adComplete', function () {
                            benchmarks.resetState();
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        // Ensures Permutive is loaded into the window before initializing.
        player.on('ready', function () {
            var _a;
            if (typeof ((_a = window.permutive) === null || _a === void 0 ? void 0 : _a.track) === 'function') {
                permutiveEvents();
            }
            else {
                dependencyReady.waitFor('permutive', permutiveEvents);
            }
        });
        return [2 /*return*/];
    });
}); };
var pluginRegistration = window.jwplayerPluginJsonp || window.jwplayer().registerPlugin;
pluginRegistration('jwpermutive.bundle.min', '8.0', jwPermutive);
