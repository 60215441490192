export var INITIAL_STATE = [
    { value: 0, sent: false },
    { value: 0.25, sent: false },
    { value: 0.5, sent: false },
    { value: 0.75, sent: false },
    { value: 0.95, sent: false },
];
export var progressState = {
    // Deep copy of initial state
    _state: JSON.parse(JSON.stringify(INITIAL_STATE)),
    get state() {
        /* eslint-disable no-underscore-dangle */
        return this._state;
    },
    updateState: function (index, newStatus) {
        this._state[index].sent = newStatus;
    },
    resetState: function () {
        this._state = JSON.parse(JSON.stringify(INITIAL_STATE));
        /* eslint-enable no-underscore-dangle */
    },
};
