/**
 * Checks whether the user is logged in.
 *
 * @returns {boolean} Returns true if the user is logged in, false otherwise.
 */
var isUserLoggedIn = function () {
    var _a;
    var localStorage = window.localStorage;
    var session = localStorage ? JSON.parse(localStorage.getItem('zephr')) : null;
    return session !== null && Boolean((_a = session === null || session === void 0 ? void 0 : session.user) === null || _a === void 0 ? void 0 : _a.sessionId);
};
export default isUserLoggedIn;
