var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { convertObjectToArray, calcProgressPercentage, } from './helpers';
import isUserLoggedIn from '../zephr/utils';
// jwplayer can be called as an object but TS will complain so we silence it.
// @ts-ignore
var playerMeta = jwplayer.defaults;
/**
 * Creates tracking data for Permutive events based on eventName.
 *
 * @param {Player} player - The JW Player instance.
 * @param {PostMeta} postMeta - The metadata for the post.
 * @param {string} eventName - The name of the Permutive event (not the JW Player event).
 * @param {AdItem} adItem - The ad data.
 *
 * @returns {Object} The tracking data object.
 */
var createTrackingData = function (_a) {
    var _b, _c, _d, _e, _f;
    var player = _a.player, videoMeta = _a.videoMeta, postMeta = _a.postMeta, eventName = _a.eventName, adItem = _a.adItem;
    var customProperties = postMeta.customProperties;
    // Build the default tracking data object common to all events.
    // This is the same as the VideoLoad interface.
    var defaultTrackingData = {
        auto_start: playerMeta.autostart || false,
        play_id: playerMeta.pid || '',
        video: {
            created_at: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.created) || null,
            custom_fields: convertObjectToArray((_b = videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata) === null || _b === void 0 ? void 0 : _b.custom_params) || [],
            description: ((_c = videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata) === null || _c === void 0 ? void 0 : _c.description) || '',
            duration: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.duration) || 0,
            name: ((_d = videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata) === null || _d === void 0 ? void 0 : _d.title) || '',
            published_at: ((_e = videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata) === null || _e === void 0 ? void 0 : _e.publish_start_date) || null,
            tags: ((_f = videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata) === null || _f === void 0 ? void 0 : _f.tags) || [],
            video_id: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.id) || '',
        },
    };
    if (eventName === 'VideoLoad') {
        return defaultTrackingData;
    }
    if (eventName === 'VideoPlay' || eventName === 'VideoProgress') {
        var videoPlayTrackingData = defaultTrackingData;
        // Add additional tracking data for VideoPlay.
        videoPlayTrackingData = __assign(__assign({}, videoPlayTrackingData), { factiva: {
                region_codes: [],
                regions: [],
                subject_codes: [],
                subjects: [],
            }, type: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.media_type) || '', video_player: {
                adview: 0,
                playerformat: '',
                plid: playerMeta.pid || '',
                sub: '',
            }, series: {
                description: '',
                name: '',
            }, page: {
                channel: '',
                description_url: '',
                env: '',
                hl: '',
                id: '',
                iu: [],
                keywords: [],
                package: [],
                section: customProperties.section || '',
                subchannel: '',
                subsection: '',
                type: '',
                url: '',
                usertype: '',
            }, subscriber: isUserLoggedIn() || false, section: customProperties.section || '', pageUri: customProperties.pageUri || '', article: {
                authors: customProperties.article.authors || [],
                id: customProperties.article.id || '',
                keywords: customProperties.article.keywords || [],
                page: customProperties.pageUri || '',
                primaryTag: customProperties.article.primaryTag || '',
                publish: customProperties.article.publish || '',
                section: customProperties.section || '',
                title: customProperties.article.title || '',
                type: customProperties.type || '',
                wordCount: customProperties.article.wordCount || 0,
            }, video: __assign(__assign({}, videoPlayTrackingData.video), { adcat: '', asset_date_path: '', author: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata.author) || '', column: '', content_status: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.status) || '', cxense_keywords: [], description: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata.description) || '', duration: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.duration) || 0, formatted_duration: "".concat(Math.floor(videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.duration), " seconds") || '0 seconds', guid: '', keywords: [], keywords_omni: [], length: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.duration.toString()) || '0', link_url: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata.permalink) || '', lnid: '', omni_program_name: '', 
                // VideoPlay triggers as soon as a video starts playing,
                // so progress is always 0 unless user seeks to trigger VideoPlay again.
                progress: calcProgressPercentage(player.getCurrentTime(), player.getDuration()) || 0, provider: player.getProvider().name || '', sponsored: '', state: player.getState() || '', sym: [], title: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata.title) || '', ttid: '', video_id: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.id) || '', videoformat: '', watch_count: 0 }) });
        if (eventName === 'VideoPlay') {
            return videoPlayTrackingData;
        }
        // If it's not a VideoPlay event, then it's a VideoProgress event.
        // The differences are: auto_start replaced with seeked,
        // length is a number instead of a string,
        // adds an additional progress property to top level of object.
        var videoProgressTrackingData = __assign(__assign({}, videoPlayTrackingData), { seeked: player.seeked, video: __assign(__assign({}, videoPlayTrackingData.video), { length: parseInt(videoPlayTrackingData.video.length, 10), progress: videoPlayTrackingData.video.progress }), progress: videoPlayTrackingData.video.progress });
        delete videoProgressTrackingData.auto_start;
        return videoProgressTrackingData;
    }
    // VideoAdPlay and VideoAdClick send the same data.
    // VideoAdPlay and VideoAdProgress are the same except for
    // the additional progress property in top level of VideoAdProgress.
    if (adItem
        && (eventName === 'VideoAdPlay'
            || eventName === 'VideoAdClick'
            || eventName === 'VideoAdProgress')) {
        var data = adItem.ima.ad.data;
        var adPlayTrackingData = {
            type: data.contentType || '',
            video_ad: {
                attribution_params: data.attributionParams.split(',') || [],
                deal_id: adItem.dealId || '',
                description: adItem.description || '',
                duration: adItem.duration || 0,
                media_url: data.mediaUrl || '',
                pod_index: data.adPodInfo.podIndex || 0,
                // VideoAdPlay triggers as soon as an ad starts playing,
                // so progress is usually 0.
                progress: calcProgressPercentage(adItem.position, adItem.duration) || 0,
                skippable: data.skippable.toString() || '',
                title: data.title || '',
                total_ads: data.adPodInfo.totalAds || 0,
                trafficking_parameters: data.traffickingParameters.split(',') || [],
                ui_elements: data.uiElements || [],
                vast_media_height: data.vastMediaHeight || 0,
                vast_media_width: data.vastMediaWidth || 0,
                vpaid: data.vpaid.toString() || '',
            },
            ad: {
                ad_id: adItem.adId || '',
                ad_system: adItem.adsystem || '',
                advertiser_name: adItem.advertiser || '',
                creative_id: adItem.creativeAdId || '',
                deal_id: adItem.dealId || '',
                description: adItem.description || '',
                duration: adItem.duration || 0,
                id: adItem.id || '',
                min_suggested_duration: data.minSuggestedDuration || 0,
                pod_info: JSON.stringify(data.adPodInfo) || '',
                position: data.adPodInfo.adPosition || 0,
                query_id: parseInt(data.adQueryId, 10) || 0,
                system: adItem.adsystem || '',
                title: data.title || '',
                universal_ad_id: JSON.stringify(data.universalAdIds) || '',
                universal_ad_id_registry: data.universalAdIdRegistry || '',
            },
            play_id: defaultTrackingData.play_id,
            ad_wrapper: {
                creative_ids: data.adWrapperCreativeIds || [],
                ids: data.adWrapperIds || [],
                systems: data.adWrapperSystems || [],
            },
            subscriber: isUserLoggedIn() || false,
            section: customProperties.section || '',
            pageUri: customProperties.pageUri || '',
            article: {
                authors: customProperties.article.authors || [],
                keywords: customProperties.article.keywords || [],
                primaryTag: customProperties.article.primaryTag || '',
                publish: customProperties.article.publish || '',
                title: customProperties.article.title || '',
                wordCount: customProperties.article.wordCount || 0,
            },
            video: __assign(__assign({}, defaultTrackingData.video), { keywords: [], length: Math.floor(videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.duration) || 0, 
                // All properties in this object is for videos, not ads, so progress is always 0.
                progress: calcProgressPercentage(player.getCurrentTime(), player.getDuration()) || 0, title: (videoMeta === null || videoMeta === void 0 ? void 0 : videoMeta.metadata.title) || '', watch_count: 0 }),
        };
        if (eventName === 'VideoAdPlay' || eventName === 'VideoAdClick') {
            return adPlayTrackingData;
        }
        // If it's not a VideoAdPlay or VideoAdClick event, then it's a VideoAdProgress event.
        var videoAdProgressTrackingData = adPlayTrackingData;
        videoAdProgressTrackingData = __assign(__assign({}, videoAdProgressTrackingData), { progress: videoAdProgressTrackingData.video_ad.progress });
        return videoAdProgressTrackingData;
    }
    throw new Error('Unable to create tracking data.');
};
export default createTrackingData;
